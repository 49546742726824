import React, { useState } from "react";
import YoutubeSlider from "../YoutubeSlider/youtubeSlider";
import "./styles.scss";

function Curriculum() {
  const [tabSelected, setTabSelected] = useState("1");

  return (
    
    
    <div className="hiring-main-curriculum">


      <YoutubeSlider />
    </div>
  );
}

export default Curriculum;
