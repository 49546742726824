import React from "react";
import LazyLoad from "react-lazyload";

import "./styles.scss";

function Card2({ image, name, company, description,extra, extraText,fn, customStyles={} }) {

  return (
    <div className="card-type2-section" style={{...customStyles}} onClick={() => fn([image, name, company, description, extraText])}>
      <div className="type2-head">
        <div className="type2-img-div">
          <LazyLoad once>
            <img
              src={image}
              alt={name + " " + company}
              title={name + " " + company}
              className="recruiter-img"
            />
          </LazyLoad>
        </div>

        <div className="type2-info">
          <div className="name">{name}</div>
          <div className="company">{company}</div>
          <div className="deco-line"></div>
        </div>
      </div>

      <div className="type2-description">{description}</div>
      <div className="type2-description-extra">{extra}</div>
      <div className="type2-description">{extraText}</div>
    </div>
  );
}

export default Card2;
