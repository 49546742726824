export const faqsData = [
  {
    title:
      "What is the batch profile? What kind of candidates do you usually get in a batch?",
    description: `<p>The Marketing Launchpad is for people who wish to start their journey in digital marketing roles like SEO/ Paid Media/ Content & Social marketing. We handpick candidates so you can hire high-potential digital marketers based on their screening test results.</p>
    <p class='mtop-2'>Historically, we have had a higher percentage of fresh graduates (graduated in the last 2-3 years) with a mix of candidates holding master's degrees or work experience of up to 2 years. These candidates have performed well in the screening test and are further put through multiple evaluations during their program.</p>`,
  },
  {
    title: 
      "Are these candidates experienced? How much experience do they typically hold?",
    description: `<p>Though we have candidates coming from diverse educational and work backgrounds, around 45 per cent of the batch has an average work experience of 15 months. The rest are freshers that are moulded by rigorous training. So when you hire freshers, the transition remains smooth. </p>`,
  },
  {
    title: 
      "How do you build the curriculum of the Marketing Launchpad? Can we have a look at it?",
    description: `<p>Kraftshala’s digital marketing course curriculum is a synthesis of a series of discussions, inspirations, experiences, and expectations from the top performance and branding experts. Our specialised classes focus on only those skills that are relevant to our students to become top-notch digital marketers in today’s age.</p>
    <p class='mtop-2'>Our curriculum managers partner with hiring managers and subject matter experts from leading companies to figure out the skills to be demonstrated at the workplace. The Marketing Launchpad is designed to build these skills through a thorough understanding of the roles and responsibilities that you will be managing. So you can hire the best from the lot, irrespective of them being freshers or having some past experience.</p>
    <p class='mtop-2'>We also have a strong feedback loop where the data on candidates’ performance is used to improve the learning experience continuously.</p>
    <p class='mtop-2'>Here is the link to the <a href="https://docs.google.com/document/d/1gW9q7MHSMQyJgjFfbhK-C2A4tYcB4PXvyjVO49tPEpc/edit#" target="_blank" class='page-link'> MLP Curriculum</a> </p>`,
  },
  {
    title: "Why should we hire from you?",
    description: `<p>Kraftshala's <a href="https://kraftshala-dot-yamm-track.appspot.com/1JWlsP1j47u8jJqhSWSRkt3hN5ulRaPqGMCcP1y-XBk8mgOYigAEZCSJwjZGOyI_CUHVqQ27OHIDtTGjLRzgS0J2ar6djPcqum_7Rgvt52KyWE-nwhGfoEHUkgq67X8UBzG4-wYIDfEWFrDriBRRBrH4LHaGEZ0h9-2_mXvHkJbYwtk4yh_u3TGqKcJHD_WD4qAF-bBujbUM6MDUQLaOmAbWd6n_0E-PM8avogGp-nkb4vOjXMwSPvuAyd5CrcZok21X4gZLS4JjmJUuIjs_X3436BCKQZlTtsm0JYmYypZvcyNZZ67Fao1T6bAor5t60BVqfJ4a_NncpjjFqay96zN_3nJW6S2epRJ3dU8PKOog3" target="_blank" class='page-link'> MarketingLaunchpad </a> has now become the preferred way for young Indians to start their marketing journey. You should hire from us because:<br/>
      <ul class='mtop-1'>
        <li> We offer you a highly skilled and competitive batch to hire digital marketers from. From an average of placing 8 students/month between Jan- Aug 2021, we are now training and placing 100+ students every month in jobs paying between INR 4.5- 8 lakhs/year</li>
        <li> You will hire digital marketers from the talent pool at zero cost.</li>
        <li> You will have access to a diverse pool of talent with specializations in - 
          <ul class='inner-list'> 
          <li> Paid Media (Facebook, Google and Amazon ad platforms)  </li>
          <li> SEO  </li>
          <li> Content and Social Media (newly launched) </li>
          </ul>
        </li>
      </ul>
    </p>`,
  },
  {
    title:
      "What kind of recruiters have been hiring from Kraftshala?",
    description: `<p>Recruiters who hire from us include well-known brands (Tata Cliq, Coursera, Urban Company, Toothsi, Masai, Mobstac etc) as well as all of the top agencies (Publicis, Performics, Merkle Sokrati, Group M,  Dentsu, Interactive Avenues, Growisto etc).</p>`,
  },
  {
    title:
      "What is the minimum CTC to be offered to these candidates?",
    description: `<p>The minimum CTC of a student when recruiters hire from us is 4.5 LPA. The maximum has gone up to 8.5 LPA.</p>`,
  },
  {
    title:
      "How much money do you charge to provide digital talent?",
    description: `<p>You hire talent from us at absolutely no cost.</p>`,
  },
  {
    title: 
      "What kind of roles or jobs will these candidates fit in?",
    description: `<p>These candidates fit well in the following roles/job areas,<br/>
    a. Paid Media (Facebook, Google and Amazon ad platforms)<br/>
    b. SEO <br/>
    c. Content and Social Media (newly launched)</p>`,
  },
  {
    title: 
      "Can we look at the ranking of the batch to shortlist candidates directly?",
    description: `<p>Yes, while sharing the list of applicants with you, our Recruiter Partnerships Team will share the candidates’ rankings with you. You may feel free to use that as a shortlisting criterion for when you decide to hire from us.</p>`,
  },
  {
    title: " We hire freshers for digital roles periodically throughout the year. Can we get into a bond or some kind of partnership to fulfil our requirements? Is there any calendar available to check the yearly hiring schedule?",
    description: `<p>Yes, we already have multiple repeat recruiters who hire from us around 3-4 times a year. Our Recruiter Partnerships Team can share the details with you along with the placement calendar for the current year.</p>`,
  },
  {
    title: 
      " Let’s suppose, we hire someone and end up not liking his performance, will there be any fee charged if we ask this candidate to leave the organization?",
    description: `<p>There will be no money charged. We always encourage the feedback mechanism at the workplace and we believe that if the candidate fails to perform after getting multiple feedbacks, you can very well let the candidate go and we will take them back into our pool, work on their training incorporating your feedback to aim for actual progress with the candidate and then put them up for placement again.</p>`,
  },
  {
    title: 
      "How long is the placement process? How many slots do you have?",
    description: `<p>The placements process for a single batch lasts up to 3 weeks and we have 3 equivalent slots available for you to hire from us.</p>`,
  },
  {
    title: 
      " I am looking to hire high-potential candidates/digital marketers. How can I participate in the first slot of the hiring process?",
    description: `<p>Sure, the slotting for recruiters is done based on the job specifications, including the role (whether it is a direct hire or an internship before PPO), salary offered, company profile, etc. We also have separate slots for agencies and clients. The Recruiter Partnerships Team will share more details with you.</p>`,
  },
  {
    title: 
      "Can we hire only interns? We are not sure if we can offer a full-time position.",
    description: `<p>Yes, you can hire interns from us as well. We believe this will add to their experience and will further help the candidates fetch a better role. Though while slotting the companies, we will give preference to recruiters offering full-time roles first.</p>`,
  },
  {
    title: 
      " What is the batch size of MLP?",
    description: `<p>The current batch size of MLP is 180 candidates.</p>`,
  },
  
];
