import React, { useEffect, useState } from "react";
import Input from "./Input/input";
import "./styles.scss";
import PhoneInputInternational from "./PhoneInput/phoneInput";
import { CONVEX_API_URL } from "../../../../config/config.js";

let formData = {
  fnameValue: "",
  lnameValue: "",
  emailValue: "",
  phoneValue: "",
  organisationValue: "",
  more_about: "",
};

function KraftshalaHiringForm(props) {
  let params = new URLSearchParams(props.search);

  const [detectDropOut, setdetectDropOut] = useState(false);

  const [btnClicked, setBtnClicked] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showInEligible, setShowInEligible] = useState(false);

  const [formFields, setFormFields] = useState(formData);

  const [checkType, setType] = useState(false);
  const [scrollValidation, setScrollValidation] = useState(false);

  const [allFilled, setAllFilled] = useState(false);

  const [errorOccured, setErrorOccured] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);


  useEffect(() => {
    const scrollToErr = () => {
      const errElement = document.querySelector(".validation-err");

      if (errElement) {
        errElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    };

    scrollToErr();
  }, [scrollValidation]);



  const getValuesFn = (data) => {
   
    setType(!checkType);
    formData[data.id] = data.value;

    if(data.id === "email"){
      formData[data.id] = data.value.toLowerCase();
    }
    
    if (formData.fnameValue && formData.lnameValue && formData.emailValue && detectDropOut === false) {
      setdetectDropOut(true);
    }

    checkAll();


    setFormFields(formData);
  };

  const checkAll = () => {
    const isComplete = [];

    Object.keys(formFields).forEach(function (key) {
      if (formFields[key] === "") {
        isComplete.push(false);
      } else {
        isComplete.push(true);
      }

    });

    if (isComplete.includes(false)) {
      setAllFilled(false);

      return false;
    }
 
    setAllFilled(true);

    return true;
    
  };

  const generateFetch = (url, params = {}, method) => {
    let options = {
      method,
    };

    url += "?" + new URLSearchParams(params).toString();

    return fetch(url, options).then((response) => response.json());
  };


  const submitFn = async () => {
    setScrollValidation(!scrollValidation); //to scroll page where user has not enter data
    setBtnClicked(true);

    const pass = checkAll(); // to check if all the fields are filled

    if (pass) {
      setBtnLoading(true); //enable loading of button

      try {

        let leadData = {
          name:formFields.fnameValue + " " +formFields.lnameValue,
          email:formFields.emailValue,
          phone: formFields.phoneValue,
          organization: formFields.organisationValue,
          designation: formFields.more_about,         
        };

        const postLeadData = await fetch(`${CONVEX_API_URL}/company-hiring-form/submit`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(leadData),
        });
        
        const resData = await postLeadData.json();

        if (resData.message == "Invalid Email ID.") {
          setEmailNotExists(true);
          setBtnLoading(false);

          return null;
        }

        if (resData.message == "Lead already exists.") {
          setEmailAlreadyExists(true);
          setBtnLoading(false);

          return null;
        }

        setShowSuccess(true);
        setShowForm(false);
        setShowInEligible(false);
        scrollToMessage("thank-you-screen");

        
       
      } catch (err) {
        setBtnLoading(false);
        console.log("err", err);
        setErrorOccured(true);
      }

      // }
    } else {
    }
  };
 
  const scrollToMessage = (className) => {
    const errElement = document.querySelector(`.${className}`);

    if (errElement) {
      errElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  return (
    <div className="main-hiring-form">
      {showForm && (
        <div className="csm-form-content">
          { (
            <React.Fragment>
              <Input
                type="text"
                name="fnameValue"
                placeholder="First name*"
                isRequired={true}
                btnClicked={btnClicked}
                getValuesFn={getValuesFn}
                value={formFields.fnameValue}
              />
              <Input
                type="text"
                name="lnameValue"
                placeholder="Last name*"
                isRequired={true}
                btnClicked={btnClicked}
                getValuesFn={getValuesFn}
                value={formFields.lnameValue}
              />
              <Input
                type="email"
                name="emailValue"
                placeholder="Work email*"
                isRequired={true}
                btnClicked={btnClicked}
                getValuesFn={getValuesFn}
                value={formFields.emailValue}
              />
              <PhoneInputInternational
                type="tel"
                name="phoneValue"
                placeholder="Phone Number*"
                isRequired={true}
                btnClicked={btnClicked}
                getValuesFn={getValuesFn}
                value={formFields.phoneValue}
              
              />
              <Input
                type="text"
                name="more_about"
                placeholder="Designation*"
                isRequired={true}
                btnClicked={btnClicked}
                getValuesFn={getValuesFn}
                value={formFields.more_about}
              />
              <Input
                type="text"
                name="organisationValue"
                placeholder="Company*"
                isRequired={true}
                btnClicked={btnClicked}
                getValuesFn={getValuesFn}
                value={formFields.organisationValue}
              />

              <div className="mlp-submit">
                {btnLoading ? (
                  <div
                    className={`submit-btn ${
                      allFilled ? "yellow-bg" : "gray-bg"
                    }`}
                  >
          Loading...
                  </div>
                ) : (
                  <div
                    className={`submit-btn ${
                      allFilled ? "yellow-bg" : "gray-bg"
                    }`}
                    onClick={submitFn}
                    aria-hidden="true"
                  >
          Hire Talent
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      )}
      {showSuccess && (
        <div className="success-screen">
          <div className="form-head">
        Learn more about hiring Kraftshala candidates. No hiring fees.
          </div>
          <div className="success-msg">
            <span>
              <img
                src="https://res.cloudinary.com/judiscloud/image/upload/v1570717139/check_circle-24px_yxucst.png"
                alt="success tick"
              />
            </span>
          Thank you for showing interest. We will get back to you soon !
          </div>
        </div>
      )}

    </div>
  );
}

export default KraftshalaHiringForm;
