import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";

const HeroSection = (props) => {
  const [openVideo, setOpenVideo] = useState(false);

  const showModalHandler = () => {
    setOpenVideo(true);
  };

  const hideModalHandler = () => {
    setOpenVideo(false);
  };

  return (
    <div className="hero-sec-container">
      <div className="hired-section">
        <h1 className="hire-from-us-content-section">
          Hire Entry-Level {" "}
          <span className="yellow-txt"> 
            Trained Marketing and Sales Talent
          </span>
        </h1>

        <div className="sec-subtitle">
          Save time and effort in hiring junior
          <b>  marketing and sales talent. </b> <span className="text-strokes">
            <StaticImage
              src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t5vrsy.webp"
              alt="underline"
              className="mobile-hide"
            />
          </span> Selected candidates available to join immediately.
      
        </div>
      
        <div className="sec-description mtop-2">

          <div className="batch-info desc-2 mtop-1">
            Save time and effort in hiring junior<b> marketing and sales talent.</b> {" "}
            <span className="text-strokes">
              <StaticImage
                src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t5vrsy.webp"
                alt="underline"
                className=""
              />
            </span>
            Selected candidates available to join immediately.
          </div>
          {/* <div className="sec-content">
            Selected candidates available to join immediately.
          </div> */}
        </div>
      </div>
      {openVideo ? (
        <React.Fragment>
          <div
            className="spec-mask spec-active"
            onClick={hideModalHandler}
            aria-hidden="true"
          ></div>
          <div className="spec-modal">
            <img
              src="https://kfwebassets.s3.ap-south-1.amazonaws.com/MLP_main/cross-white.svg"
              alt="cross icon"
              className="spec-modal-cross"
              onClick={hideModalHandler}
              aria-hidden="true"
            />
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/QgDxj-1wqDo?autoplay=1"
              title="Kraftshala YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="youtube-player"
            ></iframe>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default HeroSection;
