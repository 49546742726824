import React, { useState } from "react";
import "./styles.scss";
import { StaticImage } from "gatsby-plugin-image";

const data = [
  {
    image_url: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m200kb84.png"
    ,
    title: "Curated Pool",
    description: "Less than 1 in 5 test-takers are offered admission",
  },

  {
    image_url: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m200m39i.png"
    ,
    title: "600+ Hours of Immersive Training",
    description:
      "In Performance Marketing/ SEO/ Content/ Social Media/ E-Com",
  },

  {
    image_url: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m200nn0s.png"
    ,
    title: "Team Collaboration Skills",
    description:
      "Trained in communication, teamwork, and client handling",
  },
  {
    image_url: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m200qbat.png"
    ,
    title: "Industry-Driven Marketing Frameworks",
    description: "Built by Industry Experts to help students get hands-on practical experience",
  },

  {
    image_url: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m200rhv9.png",
    title: "Available in Every State, Immediately",
    description:
	  "Candidates available to join in your location immediately",
  },

  {
    image_url:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m200tbf7.png"
    ,
    title: "Free, Convenient and Suitable",
    description:
	  "Get a curated pool matched to your specific requirements for free",
  },

];


function Placements() {
  const [openVideo, setOpenVideo] = useState(false);

  const showModalHandler = () => {
    setOpenVideo(true);
  };

  const hideModalHandler = () => {
    setOpenVideo(false);
  };

  return (
    <div className="hiring-page-section-div">

      <h2 className="card-head hire-us-card-head">
        Why hire from India's premier <br/> marketing training platform?
    
        <span className="about-us-story-underline-img">
          <StaticImage
            className="mobile-hide"
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t5vrsy.webp"
            alt="Digital Marketing Recruiting Partners for The Marketing Launchpad"
            title="Recruiting partners"
            placeholder="none"
          />
        </span>
	  </h2>



      <div className="card-container card-container-hireUs">
        {data.map((info, k) => {
          return (
            <div key={k} className="card-div">
              <div className="card-icon">
                <img src={info.image_url} alt={info.title} />
              </div>

              <div className="card-info">
                <h3 className="card-title">{info.title}</h3>

                <div className="card-description">{info.description}</div>
              </div>
            </div>
          );
        })}
      </div>
      {openVideo ? (
        <React.Fragment>
          <div
            className="spec-mask spec-active"
            onClick={hideModalHandler}
            aria-hidden="true"
          ></div>
          <div className="spec-modal">
            <img
              src="https://kfwebassets.s3.ap-south-1.amazonaws.com/MLP_main/cross-white.svg"
              alt="cross icon"
              className="spec-modal-cross"
              onClick={hideModalHandler}
              aria-hidden="true"
            />
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/QgDxj-1wqDo?autoplay=1"
              title="Kraftshala YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="youtube-player"
            ></iframe>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}

export default Placements;
