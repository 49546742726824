

export const placementsData = [
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m203pwcs.webp",
    name: "Suchit Sikaria",
    company: "Managing Partner, at Performics Convonix",
    description: `Kraftshala adds a lot of value to the hiring process through MLP. The exhaustive curriculum, practical learning approach, and involvement of industry 
    practitioners as trainers make a lot of difference. We’ve successfully seen a smoother transition of the freshers to the workplace from this batch which has reduced the time of transition to at least one or two months owing to this training—looking forward to hiring graduates from MLP over the following years!`,
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m203ii7d.webp"
    ,
    name: "Sarung Subnani",
    company: "Senior Manager, Talent Acquisition, Splash Learn",
    description: `Kraftshala has truly positioned itself right. The curriculum is as apt as it can be and the quality of candidates was overwhelming to the extent that we made more offers than planned! The team working behind the scenes was true support, 
    from detailing the candidates to understanding the roles and pitching them well to potential candidates. We certainly look forward to hiring from Kraftshala again.`,
  },
  {
    image:
      "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m203mi8d.webp",
    name: "Shravani Rangaswamy",
    company: "HR Analyst, Indigenesis Consulting Pvt. Ltd.",
    description: `Our association with Kraftshala, in my personal capacity has been by far a
        positive one. Their professionalism, quality, response time is faultless. We were able
        to hire the right interns for our firm within a very short period of time. I strongly
        recommend Kraftshala for hiring marketing talent.`,
  },
];
